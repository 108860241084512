angular
  .module("meanApp")
  .controller("proposed-lending-controller-2", function (
    $scope,
    $rootScope,
    $state,
    $stateParams,
    $filter,
    DataM,
    $window,
    Auth,
    empowerDataService,
    $timeout,
    Idle,
    DataHelper
  ) {
    // for future use when save/discard will be applied here...
    $scope.showBottomButtons = true;


    $scope.repaymentFrequencies = [
      "Weekly",
      "Fortnightly",
      "Monthly",
      "Bi-Monthly",
      "Quarterly",
      "Yearly"
    ];
    /* $scope.propertyTypes = ['House', 'Duplex/Semi-Detached House', 'Apartment', 'Unit/Flat', 'Townhouse/Terrace', 'Villa', 'Studio',
       'Acreage/Semi-Rural', 'Alpine', 'Commercial Property', 'Commercial Land', 'Cropping', 'Dairy', 'Estate', 'Farmlet', 'Horticulture', 'Livestock', 'Mixed Farming', 'Residential Land',
       'Retirement Living', 'Serviced Apartment', 'Unitblock', 'Viticulture', 'Warehouse', 'Other'
     ];*/
    $scope.propertyTypes = [
      {
        n: "Owner Occupied",
        v: "Own Home"
      },
      {
        n: "Investment Property",
        v: "Investment"
      },
      {
        n: "Personal Use",
        v: "Personal Use"
      },
      {
        n: "Business Use",
        v: "Business Use"
      }
    ];

    $scope.loanTypes = [
      "Home Loan - Basic Variable",
      "Home Loan - Standard Variable",
      "Home Loan - Fixed Rate",
      "Home Loan - Line of Credit (Personal Use)",
      "Investment Loan - Basic Variable",
      "Investment Loan - Standard Variable",
      "Investment Loan - Fixed Rate",
      "Investment Loan - Line of Credit (Investment Use)",
      "Credit Card",
      "Car Loan",
      "Personal Loan",
      "ATO or Centrelink Debt",
      "HECS/HELP Debt",
      "Hire Purchase",
      "Lease",
      "Overdraft",
      "Store Card",
      "Commercial Bill",
      "Charge Card",
      "Other"
    ];

    $scope.primaryPurposes = [
      "Buy Real Estate",
      "Refinance Real Estate",
      "Debt Consolidation",
      "General Spending",
      "Other Purchases/Items"
    ];

    $scope.isPure = $stateParams.pure || false;

    // All loans
    $scope.loans = [];

    // proposed loans
    $scope.proposedLoans = [];

    // Loans by property
    // Existing Loans
    $scope.propertyLoans = [];

    $scope.borrowerList = [];
    $scope.properties = [];
    $scope.addressText = "";
    $scope.addressText = "";

    $scope.overallLimit = 0;
    $scope.overallBalance = 0;
    $scope.propertyLVR = 0;
    $scope.currentProperty = null;
    $scope.empowerService = empowerDataService;

    $scope.selectedPropertyId = null;
    $scope.showSpinner = false;
    $scope.loanStructureSnapList = [];
    $scope.clientId = $stateParams.clientId;
    $scope.currentPropertyId = '';
    $scope.propertyId = $scope.currentPropertyId;
    $scope.currentTimestamp = "";
    $scope.settledLoanDetails = null;

    $scope.isAddressManualFrontEndOnly = false
    $scope.addressClass = '';
    $scope.getAddressClass = function () {
      return $scope.addressClass;
    }
    $scope.setAddressValidToFalse = function () {
      $scope.isAddressValid = false;
      $scope.addressClass = "notValid"
    }
    $scope.switchToManual = function (arr, value) {
      arr.forEach((val, key) => {
        $scope[val] = value
      })
    }
    $scope.isAddressValid = ''

    $scope.updateProperyFormatted = function (address) {
      address.formatted = [
        address.floor,
        [address.unit, address.number].filter(Boolean).join('/'),
        address.street,
        address.streetType,
        address.suburb,
        address.postcode,
        address.state,
        address.country
      ].filter(Boolean).join(' ');
    }

    Auth.isAdmin().then(isAdmin => ($scope.isAdmin = isAdmin));
    // Reference ID in Snapshot list
    $scope.renameSnapshotData = {};
    $scope.toDeleteSnapshotData = {};
    // Initial true to show text is Save
    $scope.didChangeAfterSave = true;

    // Open modal for the Settlement
    $scope.settlementOpen = false;

    //ProposedLoans
    const initProposedLoanSummary = () => {
      $scope.proposedLoanSummary = {
        transactionType: "Refinance",
        totalBaseLending: 0,
        propertyLVR: 0,
        loanCost: 0,
        totalReq: 0
      };
    }
    initProposedLoanSummary()

    $scope.existingLoanSummary = {};
    $scope.isProposedLoanCostShown = false;

    // Bank accounts
    $scope.bankAccounts = [];
    $scope.offsetAccounts = [];

    // Snapshots
    $scope.modalsOpen = {};

    $scope.loanStructure = {
      referenceId: "Proposed Loan - " + new Date().toLocaleDateString("en-AU")
    };
    $scope.selectedLoanStructureId = "";
    $scope.newSelectedProperty = {
      id: ""
    };

    // Proposed Property
    $scope.proposedProperties = [];
    $scope.currentProposedProperty = {
      address: {}
    };
    $scope.isProposedProperty = false;

    const initGetPropertyLoans = (propertyId) => {
      initProposedLoanSummary();
      const currentPropertyId = propertyId || $scope.currentPropertyId

      DataM.getPropertyLoans().then(function (data) {
        $scope.borrowerList = DataHelper.getOwnerList(data, false, true, true);

        if (data.borrowings) {
          $scope.loans = DataHelper.formatBorrower(
            data.borrowings.borrowing.filter(
              (_borrowing) => !_borrowing.isClosed),
            data.personalInfo,
            $scope.borrowerList
          );
          $scope.loans = DataHelper.formatBorrower(data.borrowings.borrowing.filter((_borrowing) => !_borrowing.isClosed), data.personalInfo, $scope.borrowerList);
        }

        if (data.assets && data.assets.properties) {
          $scope.properties = data.assets.properties;
          if ($scope.currentProperty === null) {
            if ($scope.properties.length > 0) {
              $scope.currentProperty = $scope.properties[0];
            }
          }
        }

        if ($scope.properties.length > 0) {
          // $scope.addressText = empowerDataService.generateAddressFull($scope.currentProperty.address);
          if (currentPropertyId && currentPropertyId.length > 0) {
            $scope.selectedPropertyId = currentPropertyId;

            getCurrentProperty();
          }
          if ($scope.selectedPropertyId == null) {
            $scope.selectedPropertyId = $scope.currentProperty._id;
          }
          $scope.newSelectedProperty.id = $scope.selectedPropertyId;
        }

        if ($scope.currentPropertyId) getCurrentProperty()

        // getting offset accounts
        if (data.assets && data.assets.bankAccounts) {
          for (var $i = 0; $i < data.assets.bankAccounts.length; $i++) {
            if (data.assets.bankAccounts[$i].accountType === "Offset") {
              var $str =
                data.assets.bankAccounts[$i].institution +
                " " +
                data.assets.bankAccounts[$i].last4;
              var offsetAccount = {
                v: data.assets.bankAccounts[$i]._id,
                n: $str,
                last4: data.assets.bankAccounts[$i].last4,
                balance: data.assets.bankAccounts[$i].balance
              };
              $scope.offsetAccounts.unshift(offsetAccount);
              var bankAccount = {
                v: data.assets.bankAccounts[$i]._id,
                n:
                  data.assets.bankAccounts[$i].institution +
                  " " +
                  data.assets.bankAccounts[$i].accountType +
                  " (" +
                  data.assets.bankAccounts[$i].last4 +
                  ")"
              };
              $scope.bankAccounts.unshift(bankAccount);
            } else {
              var name =
                data.assets.bankAccounts[$i].institution +
                " " +
                data.assets.bankAccounts[$i].accountType;
              if (data.assets.bankAccounts[$i].accountType == "Other") {
                name =
                  data.assets.bankAccounts[$i].institution +
                  " " +
                  data.assets.bankAccounts[$i].otherType;
              }
              var bankAccount = {
                v: data.assets.bankAccounts[$i]._id,
                n: name
              };
              $scope.bankAccounts.unshift(bankAccount);
            }
          }
        }

        initializeProposedLoans();
        $scope.getLoanStructure();
      });
    }
    initGetPropertyLoans();

    function getLoanValues() {
      $scope.propertyLoans = []
      $scope.overallBalance = 0;
      let isSkipAddProposedLoan = false;
      if ($scope.proposedLoans && $scope.proposedLoans.length > 0) {
        isSkipAddProposedLoan = true;
      }
      for (var i = 0; i < $scope.loans.length; i++) {
        var curLoan = $scope.loans[i];
        if (curLoan.isClosed) {
          continue;
        }
        curLoan.isCrossCollateral = false;
        var isFromSelectedProperty = false;
        if (curLoan.securedAgainst && curLoan.securedAgainst.length > 0) {
          curLoan.isCrossCollateral = $scope.checkIsCrossCollateralisedProperty(
            curLoan
          );
          isFromSelectedProperty = curLoan.securedAgainst.includes(
            $scope.selectedPropertyId
          );
        }

        if (
          curLoan &&
          isFromSelectedProperty &&
          $scope.propertyLoans.indexOf(curLoan) == -1
        ) {
          $scope.propertyLoans.push(curLoan);
          if (!$scope.isProposedProperty && !isSkipAddProposedLoan) {
            addProposedLoan({provider: curLoan.provider})
          }
          $scope.propertyLoans.totalLoanIO = 0;
          $scope.propertyLoans.totalPNI = 0;
          setPMT(curLoan);
        }

        if (curLoan.outstanding && isFromSelectedProperty) {
          $scope.overallBalance += curLoan.outstanding;
        }

        if (curLoan.limit && isFromSelectedProperty) {
          $scope.overallLimit += curLoan.limit;
        }

        if (curLoan.offsets && curLoan.offsets.length > 0) {
          curLoan.offsetBenefit = 0;

          curLoan.offsets.forEach(function (element) {
            for (var j = 0; j < $scope.offsetAccounts.length; j++) {
              let curBankAccount = $scope.offsetAccounts[j];

              if (curBankAccount.v == element.offset) {
                element.accountRef = curBankAccount.n;
                element.last4 = curBankAccount.last4;
                element.balance = curBankAccount.balance;

                if (element.balance) {
                  curLoan.offsetBenefit += element.balance;
                }
              }
            }
          });
          curLoan.offsetBenefitIO =
            (curLoan.offsetBenefit * (curLoan.interestRate / 100)) / 12;
        }
      }

      var totalLoanOutstanding = 0;
      for (var i = 0; i < $scope.propertyLoans.length; i++) {

        var propLoan = $scope.propertyLoans[i];
        /**
         * $scope.propertyLVR[index] = (totalLoanOutstanding / $scope.totalPropertiesValue)*100;
         * loanTotalIO += (loan.originalAmount * (loan.interestRate / 100)) /12;
         */
        totalLoanOutstanding += propLoan.outstanding;
        if (
          propLoan.outstanding &&
          propLoan.interestRate &&
          propLoan.repaymentFreq
        ) {
          propLoan.loanIO =
            (propLoan.outstanding * (propLoan.interestRate / 100)) /
            empowerDataService.getFrequencyNum(propLoan.repaymentFreq);
        }
        getPaymentAccount(propLoan);
      }
      if (totalLoanOutstanding > 0) {
        $scope.propertyLVR =
          (totalLoanOutstanding / $scope.currentProperty.currentValue) * 100;
      }
    }

    $scope.changeSelectedProperty = function () {
      // State go
      $scope.changeCurrentProperty($scope.newSelectedProperty.id)
    };

    function getCurrentProperty() {
      $scope.currentProposedProperty = {
        address: {}
      };
      for (var i = 0; i < $scope.properties.length; i++) {
        if ($scope.selectedPropertyId == $scope.properties[i]._id) {
          $scope.currentProperty = $scope.properties[i];
          $scope.isProposedProperty = false;
        }
      }
      for (var i = 0; i < $scope.proposedProperties.length; i++) {
        if ($scope.selectedPropertyId == $scope.proposedProperties[i]._id) {
          $scope.currentProposedProperty = $scope.proposedProperties[i];
          if ($scope.currentProposedProperty.address) {
            $scope.updateProperyFormatted($scope.currentProposedProperty.address)
          }
          setAddressLineInit();
          $scope.currentProperty = {};
          $scope.isProposedProperty = true;
        }
      }
    }

    function getPaymentAccount(loan) {
      for (var i = 0; i < $scope.bankAccounts.length; i++) {
        if (
          loan.paymentAccount &&
          loan.paymentAccount == $scope.bankAccounts[i].v
        ) {
          loan.paymentAccountStr = $scope.bankAccounts[i].n;
        }
      }
    }

    function PMT(ir, np, pv, fv, type) {
      /*
       * ir   - interest rate per month
       * np   - number of periods (months)
       * pv   - present value
       * fv   - future value
       * type - when the payments are due:
       *        0: end of the period, e.g. end of month (default)
       *        1: beginning of period
       */
      var pmt, pvif;

      fv || (fv = 0);
      type || (type = 0);

      if (ir === 0) return -(pv + fv) / np;

      pvif = Math.pow(1 + ir, np);
      pmt = (-ir * pv * (pvif + fv)) / (pvif - 1);

      if (type === 1) pmt /= 1 + ir;

      return pmt;
    }

    function setPMT(curLoan) {
      let freqNum = curLoan.repaymentFreq
        ? empowerDataService.getFrequencyNum(curLoan.repaymentFreq)
        : 1;
      var rate = 0;
      var nper = 0;
      var pv = curLoan.outstanding;
      if (curLoan.interestRate > 0) {
        rate = curLoan.interestRate / 100;
        rate = rate / freqNum;
      }
      curLoan.loanMaturityYears = curLoan.term;
      curLoan.interestOnlyYears = 0;
      nper = curLoan.loanMaturityYears * freqNum;
      curLoan.pni = PMT(rate, nper, pv, null, null) * -1;

      if (curLoan.interestOnlyEndDate) {
        var dateNow = empowerDataService.generateUTCDate(new Date());
        curLoan.interestOnlyEndDate = empowerDataService.generateUTCDate(
          curLoan.interestOnlyEndDate
        );
        curLoan.interestOnlyEndYear = curLoan.interestOnlyEndDate.year();
        curLoan.interestOnlyYears =
          curLoan.interestOnlyEndYear - dateNow.year();
        nper =
          (curLoan.loanMaturityYears - curLoan.interestOnlyYears) * freqNum;
        curLoan.pni = PMT(rate, nper, pv, null, null) * -1;
      }
    }

    $scope.refinanceFlagChanged = function (loan) {
      var loanId = loan._id;
      var proposedLoan = getProposedLoanOfLoanId(loanId);
      proposedLoan.refinanceFlag = loan.refinanceFlag;
    };

    // Proposed loan
    function getProposedLoanValues() {
      $scope.proposedLoanSummary.totalBaseLending = 0;
      for (var i = 0; i < $scope.proposedLoans.length; i++) {
        var proposedLoan = $scope.proposedLoans[i];

        $scope.proposedLoanSummary.totalBaseLending += parseFloat(
          proposedLoan.baseLoan || 0
        );
      }
    }

    function getProposedLoanOfLoanId(loanId) {
      for (var i = 0; i < $scope.proposedLoans.length; i++) {
        var proposedLoan = $scope.proposedLoans[i];

        if (loanId == proposedLoan.loanId) {
          return proposedLoan;
        }
      }
    }

    function initializeProposedLoans() {
      /**
       for(var i=0; i<$scope.propertyLoans.length; i++){
        var proposedLoan = {};
        var existingLoan = $scope.propertyLoans[i];
        proposedLoan.loanId = existingLoan._id;
        proposedLoan.baseLoan = existingLoan.outstanding;
        proposedLoan.capLMI = existingLoan.LMI;
        proposedLoan.totalLoan = proposedLoan.baseLoan+proposedLoan.capLMI;
        proposedLoan.repaymentType = existingLoan.repaymentType;
        proposedLoan.loanType = existingLoan.loanType;
        proposedLoan.discountRate = existingLoan.discountRate;
        proposedLoan.baseRate = existingLoan.baseRate;
        proposedLoan.interestRate= existingLoan.interestRate;
        proposedLoan.purpose = existingLoan.primaryPurpose;
        if(proposedLoan.interestRate && proposedLoan.interestRate > 0) {
          proposedLoan.loanIO =  (proposedLoan.totalLoan * (proposedLoan.interestRate/100)) /12;
          proposedLoan.loanIO = parseFloat(proposedLoan.loanIO.toFixed(2));
        }
        setPMTProposedLoan(proposedLoan);
        $scope.proposedLoans.push(proposedLoan);
      } */
      $scope.recomputeProposedLoanValues();
    }

    $scope.recomputeProposedLoanValues = function () {
      getProposedLoanValues();

      if ($scope.proposedLoanSummary.totalBaseLending > 0) {
        if (!$scope.isProposedProperty) {
          $scope.proposedLoanSummary.propertyLVR =
            ($scope.proposedLoanSummary.totalBaseLending /
              $scope.currentProperty.currentValue) *
            100;
        } else {
          $scope.proposedLoanSummary.propertyLVR =
            ($scope.proposedLoanSummary.totalBaseLending /
              $scope.currentProposedProperty.currentValue) *
            100;
        }
      }
      $scope.computeProposedLoanCost();
    };

    $scope.computeProposedLoanCost = function () {
      if ($scope.currentProperty) {
        let currentPropertyValue = 0;
        if (!$scope.isProposedProperty) {
          currentPropertyValue = $scope.currentProperty.currentValue
            ? $scope.currentProperty.currentValue
            : 0;
        } else {
          currentPropertyValue = $scope.currentProposedProperty.currentValue
            ? $scope.currentProposedProperty.currentValue
            : 0;
        }

        if ($scope.proposedLoanSummary.transactionType == "Refinance") {
          $scope.refinanceCostChange();
          $scope.proposedLoanSummary.totalReq =
            $scope.proposedLoanSummary.loanCost + $scope.overallBalance;
        } else if ($scope.proposedLoanSummary.transactionType == "Purchase") {
          $scope.proposedLoanSummary.loanCost = 0.06 * currentPropertyValue;
          $scope.refinanceCostChange();
          $scope.proposedLoanSummary.totalReq =
            $scope.proposedLoanSummary.loanCost + currentPropertyValue;
        } else {
          $scope.proposedLoanSummary.loanCost = 0;
        }
      }
    };
    $scope.handlerLendingChange = function () {
      if (!($scope.proposedLoans[$scope.proposedLoans.length - 1]).offsets) {
        $scope.addProposedOffsetLoan($scope.proposedLoans[$scope.proposedLoans.length - 1])
      }

      $scope.checkFormIsChanged()
    }

    function addProposedLoan(provider) {
      $scope.proposedLoans.push(provider);
      $scope.addProposedOffsetLoan($scope.proposedLoans[$scope.proposedLoans.length - 1])
    }

    function setPMTProposedLoan(proposedLoan) {
      let freqNum = 12;
      var rate = 0;
      var nper = 0;

      if (
        proposedLoan.pniYears &&
        proposedLoan.repaymentType == "Principal and Interest"
      ) {
        nper = proposedLoan.pniYears * 12;
      } else {
        let pni = 0;
        if (proposedLoan.totalLoanTerm && proposedLoan.interestOnlyTerm) {
          pni =
            (proposedLoan.totalLoanTerm || 0) -
            (proposedLoan.interestOnlyTerm || 0);
        }
        nper = pni * 12;
      }

      if (proposedLoan.interestRate > 0) {
        rate = proposedLoan.interestRate / 100;
        rate = rate / freqNum;
      }
      var pv = proposedLoan.totalLoan;
      proposedLoan.pni = PMT(rate, nper, pv, null, null) * -1;
      proposedLoan.pni = parseFloat(proposedLoan.pni.toFixed(2));
      proposedLoan.pni = isFinite(proposedLoan.pni) ? proposedLoan.pni : 0;
    }

    $scope.addProposedLoan = function () {
      const provider =
        $scope.proposedLoans.length > 0
          ? $scope.proposedLoans[0].provider
          : null;
      if (provider) {
        addProposedLoan({provider})
      } else {
        alert("Please select Proposed Lender");
      }
    };

    $scope.interesRateChanged = function (loan, type, proposedLoan) {
      if (type !== "Interest") {
        if (loan.baseRate != 0 || loan.discountRate != 0)
          loan.interestRate = (loan.baseRate || 0) - (loan.discountRate || 0);
      } else {
        loan.discountRate = 0;
        loan.baseRate = 0;
      }
      // Recompute I/O and PNI
      if (loan.interestRate && parseFloat(loan.interestRate) > 0) {
        loan.loanIO = (loan.totalLoan * (loan.interestRate / 100)) / 12;
        loan.loanIO = parseFloat(loan.loanIO.toFixed(2));
      }
      setPMTProposedLoan(loan);
      $scope.getTotalPropertyLoans(loan, proposedLoan);
    };

    $scope.totalLoanChanged = function (proposedLoan) {
      if (proposedLoan.interestRate && proposedLoan.interestRate > 0) {
        proposedLoan.loanIO =
          (proposedLoan.totalLoan * (proposedLoan.interestRate / 100)) / 12;
        proposedLoan.loanIO = parseFloat(proposedLoan.loanIO.toFixed(2));
      }

      setPMTProposedLoan(proposedLoan);
    };

    $scope.loanTermChange = function (loan, e) {
      switch (e) {
        case "pniYears":
          loan.totalLoanTerm = loan.pniYears;
          break;
        case "totalLoanTerm":
          loan.pniYears = loan.totalLoanTerm;
          break;
      }

      setPMTProposedLoan(loan);
    };

    $scope.computeTotalLoanChanged = function (loan) {
      loan.totalLoan =
        parseFloat(loan.capLMI || 0) + parseFloat(loan.baseLoan || 0);
    };

    $scope.removeProposedLoan = function (proposedLoan) {
      $scope.proposedLoans.splice(
        $scope.proposedLoans.indexOf(proposedLoan),
        1
      );
    };

    $scope.addRefinancedProposedLoan = function (existingLoan) {
      var proposedLoan = {};
      proposedLoan.loanId = existingLoan._id;
      proposedLoan.baseLoan = existingLoan.outstanding;
      proposedLoan.capLMI = existingLoan.LMI;
      proposedLoan.totalLoan = proposedLoan.baseLoan + proposedLoan.capLMI;
      proposedLoan.repaymentType = existingLoan.repaymentType;
      proposedLoan.loanType = existingLoan.loanType;
      proposedLoan.discountRate = existingLoan.discountRate;
      proposedLoan.baseRate = existingLoan.baseRate;
      proposedLoan.interestRate = existingLoan.interestRate;
      proposedLoan.purpose = existingLoan.primaryPurpose;
      if (proposedLoan.interestRate && proposedLoan.interestRate > 0) {
        proposedLoan.loanIO =
          (proposedLoan.totalLoan * (proposedLoan.interestRate / 100)) / 12;
        proposedLoan.loanIO = parseFloat(proposedLoan.loanIO.toFixed(2));
      }
      setPMTProposedLoan(proposedLoan);
      addProposedLoan(proposedLoan)
      $scope.recomputeProposedLoanValues();
    };

    $scope.saveLoanStructure = function () {
      var clientID = $stateParams.clientId;
      $scope.showSpinner = true;
      $scope.savedText = "Saved";

      var formData = {
        uid: clientID,
        propertyId: $scope.selectedPropertyId,
        referenceId: $scope.loanStructure.referenceId,
        existingLoan: {
          notes: $scope.existingLoanSummary.notes,
          loanWriter: $scope.existingLoanSummary.loanWriter
        },
        proposedLoan: {
          notes: $scope.proposedLoanSummary.notes,
          contribSource: $scope.proposedLoanSummary.contribSource,
          surplusDirection: $scope.proposedLoanSummary.surplusDirection,
          shortfallDirection: $scope.proposedLoanSummary.shortfallDirection,
          appFolder: $scope.proposedLoanSummary.appFolder,
          transactionType: $scope.proposedLoanSummary.transactionType,
          refinanceCosts: $scope.proposedLoanSummary.refinanceCosts,
          propertyType: $scope.proposedLoanSummary.propertyType,
          loans: $scope.proposedLoans
        }
      };
      if ($scope.selectedLoanStructureId.length > 0) {
        formData.currentLoanStructureId = $scope.selectedLoanStructureId;
      }
      DataM.saveLoanStructure(formData)
        .then(function (data) {
          $scope.showSpinner = false;
          $scope.savedText = "Saved";
          $scope.selectedLoanStructureId = data._id;
          $scope.didChangeAfterSave = false;
          reloadSnapshotList();
          $scope.reloadPage();
        })
        .catch(function (err) {
          if (err.data && err.data.message) {
            $scope.errorMessage = err.data.message;
          } else {
            $scope.errorMessage =
              "Unable to save Loan Structure. Please contact admin.";
          }
          $scope.showSpinner = false;
        });
    };

    $scope.getLoanStructure = function (loanStructureId) {
      var clientID = $stateParams.clientId;
      $scope.showSpinner = true;

      var query = {
        uid: clientID,
        propertyId: $scope.selectedPropertyId
      };
      if (loanStructureId) {
        query.loanStructureId = loanStructureId;
      }
      DataM.getLoanStructure(query)
        .then(function (data) {
          $scope.showSpinner = false;
          if (data._id) {
            $scope.selectedLoanStructureId = data._id;
          }
          if (data.existingLoan) {
            $scope.existingLoanSummary.notes = data.existingLoan.notes;
            $scope.existingLoanSummary.loanWriter =
              data.existingLoan.loanWriter;
          }

          if (data.proposedLoan) {
            $scope.proposedLoanSummary.notes = data.proposedLoan.notes;
            $scope.proposedLoanSummary.contribSource =
              data.proposedLoan.contribSource;
            $scope.proposedLoanSummary.surplusDirection =
              data.proposedLoan.surplusDirection;
            $scope.proposedLoanSummary.shortfallDirection =
              data.proposedLoan.shortfallDirection;
            $scope.proposedLoanSummary.appFolder = data.proposedLoan.appFolder;
            $scope.proposedLoanSummary.transactionType =
              data.proposedLoan.transactionType;
            $scope.proposedLoanSummary.refinanceCosts =
              data.proposedLoan.refinanceCosts;
            $scope.proposedLoanSummary.propertyType =
              data.proposedLoan.propertyType;
            $scope.proposedLoans = data.proposedLoan.loans;
            $scope.proposedLoans.totalLoanIO = 0;
            $scope.proposedLoans.totalPNI = 0;

            initializeProposedLoans();
          }
          if (data.referenceId) {
            $scope.loanStructure.referenceId = data.referenceId;
            $scope.loanStructure._id = data._id;
          }
          $scope.currentTimestamp = data.timestamp;
          getLoanValues();
          $scope.computeProposedLoanCost();
          $scope.closeModal();
        })
        .catch(function (err) {
          console.log("error", err);
          $scope.showSpinner = false;
          $scope.closeModal();
        });
    };

    $scope.addProposedOffsetLoan = function (proposedLoan) {
      if (!proposedLoan.offsets) {
        proposedLoan.offsets = [];
      }
      proposedLoan.offsets.push({});
    };

    $scope.updateProposedLoanOffsetTotal = function (proposedLoan) {
      if (proposedLoan.offsets) {
        proposedLoan.totalOffsetBenefit = 0;
        proposedLoan.offsetBenefitIO = 0;
        for (var i = 0; i < proposedLoan.offsets.length; i++) {
          const offset = proposedLoan.offsets[i];
          if (offset.balance) {
            proposedLoan.totalOffsetBenefit += parseFloat(offset.balance);
          }
        }
      } else {
        proposedLoan.offsetBenefit = 0;
      }
      if (proposedLoan.repaymentType == "Interest Only") {
        proposedLoan.offsetBenefitIO =
          ((proposedLoan.interestRate / 100) *
            proposedLoan.totalOffsetBenefit) /
          12;
      }
    };

    $scope.offsetChange = function (offset) {
      const offsetAccounts = $scope.offsetAccounts;
      offsetAccounts.map(offsetAccount => {
        if (offsetAccount.v === offset.accountRef) {
          offset.balance = offsetAccount.balance;
          offset.last4 = offsetAccount.last4;
        }
      });
    };

    $scope.addRefinanceCost = function () {
      if (!$scope.proposedLoanSummary.refinanceCosts) {
        $scope.proposedLoanSummary.refinanceCosts = [];
      }
      $scope.proposedLoanSummary.refinanceCosts.push({});
    };

    $scope.deleteRefinanceCost = function (cost) {
      $scope.proposedLoanSummary.refinanceCosts.splice(
        $scope.proposedLoanSummary.refinanceCosts.indexOf(cost),
        1
      );
      $scope.computeProposedLoanCost();
    };
    $scope.deleteOffset = function (loan, offset) {
      loan.offsets.splice(loan.offsets.indexOf(offset), 1);
      $scope.updateProposedLoanOffsetTotal(loan);
    };
    $scope.showCostModal = function () {
      $scope.isProposedLoanCostShown = !$scope.isProposedLoanCostShown;
      $scope.computeProposedLoanCost();
    };

    $scope.refinanceCostChange = function () {
      if (
        $scope.proposedLoanSummary.refinanceCosts &&
        $scope.proposedLoanSummary.refinanceCosts.length > 0
      ) {
        $scope.proposedLoanSummary.loanCost = 0;

        for (
          var i = 0;
          i < $scope.proposedLoanSummary.refinanceCosts.length;
          i++
        ) {
          const refinanceCost = $scope.proposedLoanSummary.refinanceCosts[i];
          if (refinanceCost.amount) {
            $scope.proposedLoanSummary.loanCost += refinanceCost.amount;
          }
        }
      } else {
        if ($scope.proposedLoanSummary.transactionType === "Refinance") {
          $scope.proposedLoanSummary.loanCost = 750;
        }
      }

      if ($scope.proposedLoanSummary.transactionType === "Refinance") {
        if (
          $scope.proposedLoanSummary.loanCost === 0 ||
          !$scope.proposedLoanSummary.refinanceCosts
        ) {
          $scope.proposedLoanSummary.loanCost = 750;
        }
      }
    };

    $scope.checkIsCrossCollateralisedProperty = function (curLoan) {
      for (var i = 0; i < $scope.loans.length; i++) {
        const loan = $scope.loans[i];

        if (
          loan.securedAgainst.length > 1 &&
          loan.securedAgainst.includes($scope.selectedPropertyId)
        ) {
          return true;
        }
      }
      return false;
    };

    $scope.getAddressByPropertyId = function (propertyId) {
      for (var i = 0; i < $scope.properties.length; i++) {
        const property = $scope.properties[i];
        if (property._id == propertyId) {
          return $scope.empowerService.generateAddressFull(property.address);
        }
      }
      return "Property Not existing";
    };
    $scope.savedText = "Save";

    /* MODALS */
    $scope.closeModal = function (modalName) {
      if (modalName == null || modalName.length < 1) {
        for (var key in $scope.modalsOpen) {
          if ($scope.modalsOpen.hasOwnProperty(key)) {
            $scope.modalsOpen[key] = false;
          }
        }
      } else {
        $scope.modalsOpen[modalName] = false;
      }
    };

    $rootScope.changeCurrentProperty = function (propertyId) {
      $scope.currentPropertyId = ''
      $scope.currentPropertyId = propertyId;
      $scope.selectedPropertyId = ''
      $scope.selectedPropertyId = propertyId;
      $scope.proposedLoans = [];
      $scope.offsetAccounts = [];
      initGetPropertyLoans(propertyId);
    }

    $scope.openReferenceIdModal = function () {
      $scope.modalsOpen.referenceId = true;
    };

    $scope.openSnapshotsModal = function () {
      $scope.modalsOpen.snapshots = true;
      getLoanStructureList();
    };

    $scope.openShowPropertiesModal = function () {
      $scope.modalsOpen.showProperties = true;
    };

    $scope.openProposedProperties = function () {
      $scope.modalsOpen.showProposedProperties = true;
    };

    $scope.openAddProposedProperties = function () {
      $scope.modalsOpen.showAddProposedProperties = true;
      $scope.newProposedProperty = {
        address: {
          country: ""
        }
      };
    };
    $scope.openEditProposedProperties = function () {
      $scope.modalsOpen.showEditProposedProperties = true;
    };
    $scope.openDeleteSnapshot = function (selectedLoanStructure) {
      $scope.toDeleteSnapshotData.id = selectedLoanStructure._id;
      $scope.modalsOpen.showConfirmDeleteSnapshot = true;
    };

    $scope.goToProposedNewProperty = function () {
      // State go
      $scope.changeCurrentProperty($scope.newProposedProperty.id)
    };

    function getLoanStructureList() {
      var clientID = $stateParams.clientId;
      $scope.showSpinner = true;
      var query = {
        uid: clientID,
        propertyId: $scope.selectedPropertyId
      };
      DataM.getLoanStructureList(query)
        .then(function (data) {
          $scope.showSpinner = false;
          $scope.loanStructureSnapList = data;
        })
        .catch(function (err) {
          $scope.showSpinner = false;
          console.log("Retrieve Loan Structure list error", err);
        });
    }

    $scope.createProposedProperty = function (property) {

      var clientID = $stateParams.clientId;
      $scope.showSpinner = true;
      $scope.newProposedProperty.id = null;
      var formData = {
        uid: clientID,
        name: property.name,
        address: property.address,
        currentValue: property.currentValue
      };

      var existingProposedProperty = $scope.proposedProperties;
      var existingProperty = false;
      for (let x = 0; x < existingProposedProperty.length; x++) {
        var existingProposedPropertyAddress = $scope.empowerService.generateAddressFull(
          existingProposedProperty[x].address
        );
        var newProposedPropertyAddress = $scope.empowerService.generateAddressFull(
          property.address
        );

        if (existingProposedPropertyAddress === newProposedPropertyAddress) {
          existingProperty = true;
        }
      }

      if (!existingProperty) {
        if (property._id) {
          formData._id = property._id;
        }

        DataM.createProposedProperty(formData)
          .then(function (data) {

            $scope.showSpinner = false;

            // popup show success and link to new property
            $scope.proposedProperties.push(data)
            $scope.modalsOpen.showAddProposedProperties = false;
            $scope.modalsOpen.showSuccessProperty = true;
            $scope.newProposedProperty.id = data._id;
            setAddressLineInit()
          })
          .catch(function (err) {
            console.log("error", err);
            if (err.data && err.data.message) {
              $scope.errorMessage = err.data.message;
            } else {
              $scope.errorMessage =
                "Unable to save Proposed Property. Please contact admin.";
            }
            $scope.showSpinner = false;
          });
      } else {
        $scope.errorMessage = "Proposed Loan Property already exist!";
        $scope.modalsOpen.showAddProposedProperties = false;
        $scope.modalsOpen.error = true;
        $scope.showSpinner = false;
      }
    };
    const setAddressLineInit = function () {
      $scope.isAddressValid = true
      $scope.addressClass = 'isValid'
      $scope.isAddressManualFrontEndOnly = false
      $scope.currentProposedProperty.address.frontFormatted = _.clone($scope.currentProposedProperty.address.formatted)
      $scope.currentProposedProperty.address.unit = parseInt($scope.currentProposedProperty.address.unit)
    }
    $scope.updateProposedProperty = function () {


      DataM.updateProposedProperty($scope.currentProposedProperty)
        .then(function (data) {
          $scope.showSpinner = false;

          // Go to new property
          // popup show success and link to new property
          $scope.modalsOpen.showEditProposedProperties = false;
          setAddressLineInit()

        })
        .catch(function (err) {
          console.log("error", err);
          if (err.data && err.data.message) {
            $scope.errorMessage = err.data.message;
          } else {
            $scope.errorMessage =
              "Unable to update Proposed Property. Please contact admin.";
          }
          $scope.showSpinner = false;
        });
    };

    function getProposedPropertyList() {
      var clientID = $stateParams.clientId;

      var formData = {
        uid: clientID
      };
      DataM.getProposedPropertyList(formData).then(function (data) {
        if (data.properties) {
          $scope.proposedProperties = data.properties;
        }
        if ($scope.proposedProperties.length > 0) {
          if (!$scope.properties.length && !$scope.currentPropertyId)
            $scope.currentPropertyId = $scope.proposedProperties[0]._id

          if ($scope.currentPropertyId && $scope.currentPropertyId.length > 0) {
            $scope.selectedPropertyId = $scope.currentPropertyId;

            getCurrentProperty();
          }
          if ($scope.selectedPropertyId == null) {
            $scope.selectedPropertyId = $scope.currentProperty._id;
          }
          $scope.newSelectedProperty.id = $scope.selectedPropertyId;
        }


        initializeProposedLoans();
        $scope.getLoanStructure($scope.selectedLoanStructureId);

      });
    }

    getProposedPropertyList();

    $scope.deleteSnapshot = function () {
      var clientID = $stateParams.clientId;
      $scope.showSpinner = true;
      $scope.modalsOpen.snapshots = false;
      //delete
      let formData = {
        snapshotID: $scope.toDeleteSnapshotData.id,
        uid: clientID
      };

      DataM.deleteSnapshot(formData)
        .then(function (data) {
          // reload
          reloadSnapshotList();
          $scope.modalsOpen.snapshots = true;
        })
        .catch(function (err) {
          console.log("error delete snapshot", err);
          $scope.modalsOpen.snapshots = true;
        });
    };

    $scope.renameSnapshot = function () {
      $scope.showSpinner = true;

      let formData = {
        snapshotID: $scope.selectedLoanStructureId,
        newReference: $scope.loanStructure.referenceId
      };

      DataM.renameSnapshot(formData).then(function (data) {
        // reload
        reloadSnapshotList();
      });
    };

    function reloadSnapshotList() {
      var clientID = $stateParams.clientId;
      $scope.showSpinner = true;

      var query = {
        uid: clientID,
        propertyId: $scope.selectedPropertyId
      };

      DataM.getLoanStructureList(query)
        .then(function (data) {
          $scope.showSpinner = false;
          $scope.loanStructureSnapList = data;
          $scope.modalsOpen.showConfirmDeleteSnapshot = false;
          $scope.modalsOpen.referenceId = false;
          $scope.toDeleteSnapshotData = {};
          $scope.currentTimestamp = getTimestamp(
            $scope.selectedLoanStructureId
          );
        })
        .catch(function (err) {
          $scope.showSpinner = false;
          $scope.modalsOpen.showConfirmDeleteSnapshot = false;
          console.log("Retrieve Loan Structure list error", err);
        });
    }

    $scope.reloadPage = function () {
      getProposedPropertyList();
      reloadSnapshotList();
    };

    $scope.checkFormIsChanged = function () {
      $scope.savedText = "Save";
    };

    function getTimestamp(snapshotId) {
      var snapshotlist = $scope.loanStructureSnapList;

      for (var i = 0; i < snapshotlist.length; i++) {
        if (snapshotlist[i]._id == snapshotId) {
          return snapshotlist[i].timestamp;
        }
      }
    }

    $scope.getTotalPropertyLoans = function (loan, propertyLoans) {
      var totalIO = 0;
      var totalPNI = 0;
      propertyLoans.map(loans => {
        totalPNI += isFinite(loans.pni) ? loans.pni : 0;
        if (loans.offsets && loans.offsets.length) {
          totalIO += loans.loanIO - loans.offsetBenefitIO;
        } else {
          totalIO += loans.loanIO ? loans.loanIO : 0;
        }
      });

      propertyLoans.totalLoanIO = totalIO;
      propertyLoans.totalPNI = totalPNI;
    };

    $scope.updateCurrentLoan = function () {
      var formData = {
        borrowings: {
          borrowing: $scope.loans
        }
      };

      //SAVE TO DB
      DataM.savePropertyLoans(formData)
        .then(function (data) {
        })
        .catch(function (err) {
        });
    };

    function RenderReactSF(params) {
      let proposedLendingWrapper = document.getElementById("react-wrapper-proposed-lending-mbac");
      ReactDOM.unmountComponentAtNode(proposedLendingWrapper);
      ReactDOM.render(
        React.createElement(SettlementForm.default, params),
        proposedLendingWrapper
      );
      $scope.$on("$destroy", () => {
        ReactDOM.unmountComponentAtNode(proposedLendingWrapper);
      });
    }

    function RenderReactModal(params) {
      let proposedLendingWrapper = document.getElementById("react-wrapper-proposed-lending-mbac");
      ReactDOM.unmountComponentAtNode(proposedLendingWrapper);
      ReactDOM.render(
        React.createElement(ModalUnansweredFields.default, params),
        proposedLendingWrapper
      );
      $scope.$on("$destroy", () => {
        ReactDOM.unmountComponentAtNode(proposedLendingWrapper);
      });
    }

    $scope.openReactModal = function (clientId, propertyId, loanIndex) {
      let params = {
        userId: clientId,
        windowOpen: true,
        propertyId: propertyId,
        loanIndex: loanIndex,
        baseUrl: window.location.origin,
        onClose: function () {
          params.windowOpen = false;
          RenderReactModal(params);
        }
      };
      if (data.borrowings) {
        $scope.loans = DataHelper.formatBorrower(data.borrowings.borrowing.filter((_borrowing) => !_borrowing.isClosed), data.personalInfo, $scope.borrowerList);
      }

      RenderReactModal(params);
    };

    $scope.openSettlement = function (clientId, propertyId, loanIndex) {
      let params = {
        id: clientId,
        windowOpen: true,
        propertyId: propertyId,
        loanIndex: loanIndex,
        baseUrl: window.location.origin,
        onSubmit: function (msg) {
          if (msg.save) {
            $scope.reloadPage();
            $scope.reloadPropertyLoans();
          }
        },
        onClose: function () {
          params.windowOpen = false;
          RenderReactSF(params);
        }
      };

      RenderReactSF(params);
    };

    $scope.unlockLoan = function (loan) {
      alert(
        "Please Manully Remove the Loan in Borrowings, which is associated with this Proposoed Loan"
      );
      loan.isSettled = !loan.isSettled;
    };

    $scope.showSettledPreview = function (loanId) {
      var loans = $scope.loans;
      var settledLoanDetails = null;
      settledLoanDetails = loans
        .map(loan => {
          if (loan.settledLoan == loanId) {
            return loan;
          }
        })
        .filter(loan => {
          return loan != undefined || null;
        });
      $scope.settledLoanDetails = settledLoanDetails;
      $scope.modalsOpen.showSettledLoanDetails = true;
    };

    $scope.reloadPropertyLoans = function () {
      initGetPropertyLoans()
    };

    $scope.removeProperty = function () {
      if (!confirm("Are you sure you want to delete?")) {
        return false;
      }

      var propertyId = null;
      $scope.showSpinner = true;

      if ($scope.isProposedProperty) {
        propertyId = $scope.selectedPropertyId;
        DataM.removeProposedProperty({id: propertyId}).then(function (data) {
          // remove loans if have any
          angular.forEach($scope.loans, (loan, key) => {
            if (loan.properties[0] === propertyId) {
              // acceptable since proposedLoans can not have multiple properties
              $scope.loans.splice(key, 1);
            }
          });
          angular.forEach($scope.proposedLoans, loan => {
            $scope.removeProposedLoan(loan);
          });
          const formData = {
            borrowings: {
              borrowing: $scope.loans
            }
          };

          //SAVE TO DB
          DataM.savePropertyLoans(formData)
            .then(function (data) {
              $state.reload()
            })
            .catch(function (err) {
              alert("Problem on deleting the proposed loan.")
              console.log("Problem on deleting the proposed loan.");
            });
        });
      }
    };
  })
  .filter("percentage", [
    "$filter",
    function ($filter) {
      return function (input, decimals) {
        if (input) {
          return $filter("number")(input) + "%";
        }

        return $filter("number")(0) + "%";
      };
    }
  ])

  .directive("autoResize", function ($document) {
    return {
      restrict: "A",
      link: function ($scope, element, attrs, controller) {
        $scope.$watch(function () {
          if (element[0].value) {
            element[0].style.height = element[0].scrollHeight + 'px'
          } else {
            element[0].style.height = 'auto'
          }
        })
      }
    }
  })

  // Directive for the Formatting number to Currency, Percentage
  .directive("format", [
    "$filter",
    function ($filter) {
      return {
        require: "ngModel",
        link: function (scope, elem, attrs, ctrl) {
          if (!ctrl) return;

          ctrl.$formatters.unshift(function (a) {
            var formatted = $filter(attrs.format)(ctrl.$modelValue);
            if (ctrl.$modelValue && ctrl.$modelValue % 1 === 0) {
              formatted = formatted.replace(/\.00$/, "");
            }

            return formatted;
          });

          elem.bind("focus", function () {
            elem.val(ctrl.$modelValue);
          });

          elem.bind("blur", function (event) {
            var plainNumber = elem.val().replace(/[^\d|\-+|\.+]/g, "");
            var formatted = $filter(attrs.format)(plainNumber);

            if (plainNumber % 1 === 0) {
              formatted = formatted.replace(/\.00$/, "");
            }
            elem.val(formatted);
          });
        }
      };
    }
  ]);
