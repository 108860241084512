angular.module('meanApp')

.controller('dashboard-controller', function($scope, DataM, Auth, $state, $stateParams, empowerDataService, EmpowerWealthHelper, $rootScope, $timeout) {
  'use strict';
  $scope.isAdmin = false;
  Auth.isAdmin().then(isAdmin => ($scope.isAdmin = isAdmin));

  $scope.firstLogin = false;
  $scope.clientSchema = null;
  $scope.user = {};
  $scope.isShortFactFindEnabled = false;
  $scope.showDashboardError = false;
  $scope.dataErrorMessage = "";

  // /*Dashboard Tiles Variables*/
  $scope.clients = {};
  $scope.client1FullName = "";
  $scope.client1Id = "";
  $scope.client2FullName = "";
  $scope.client2Id = "";
  $scope.legacyId = "";
  $scope.householdIncome = 0;
  $scope.taxPayable = 0;
  $scope.surplusCashflow = 0;
  $scope.totalCash = 0;
  $scope.availableEquity = 0;
  $scope.investmentNestegg = 0;

  $scope.totalIncomeVal = 0;
  $scope.totalExpenses = 0;
  $scope.totalAssets = 0;
  $scope.empowerData = empowerDataService;

  // Hold client 1 and 2 ids
  $scope.client1Id = '';
  $scope.client2Id = '';

  // Gross annual rental income
  $scope.annualRentalIncome = {
    client1GrossRentalIncome:0,
    client2GrossRentalIncome:0
  }
    /*Property Portfolio*/
  //Need an array of properties.
  $scope.loansPropertyList = [];
  $scope.nonLoanPropertyList = [];
  //Need an array of Loans.
  $scope.totalPropertiesValue = 0;

  /*Property Loans*/
  $scope.loansTotalLimit = 0;
  $scope.loansTotalBalance = 0;
  $scope.loansTotalRepayment = 0;
  $scope.loansBalance = 0;
  $scope.currentLvr = 0;
  $scope.avlEquityAtEighty = 0;
  $scope.repayments = 0;

  /*Summary of Income*/
  /*[0] - Client 1, [1] - Client 2*/
  $scope.grossPaygIncome = [0,0];
  $scope.superannuationSalarySacrifice = [0,0];
  $scope.grossBusinessIncome = [0,0];
  $scope.grossSoleTraderIncome = [0,0];
  $scope.grossOtherIncome = [0,0];
  $scope.grossRentalIncome = [0,0];
  $scope.grossInvestmentIncome = [0,0];
  $scope.preTaxDeductions = [0,0];
  $scope.totalIncome = [0,0];

  $scope.investmentPropertyDeductions = [0,0];
  $scope.otherTaxDeductions = [0,0];
  $scope.taxableIncome = [0,0];
  $scope.estimatedTaxPaid = [0,0];
  $scope.nonTaxableIncome = [0,0];
  $scope.totalNetIncome = [0,0];

  /*Summary of Expenditure*/
  /*[0] - Monthly, [1] - Yearly*/
  $scope.totalBillPayments = [0,0];
  $scope.totalSpending = [0,0];
  $scope.totalInvestmentPropertyCosts = [0,0];
  $scope.totalOtherPropertyCosts = [0,0];
  $scope.totalLoanPayments = [0,0];
  $scope.totalContributionsOtherInvestments = [0,0];
  $scope.afterTaxSuperContributions = [0,0];

  $scope.totalExpenditure = [0,0];


  /*[0] - Monthly, [1] - Yearly*/
  $scope.incomeOverall = [0,0];
  $scope.expenditureOverall = [0,0];
  $scope.surplus = [0,0];

  /*Summary of Assets and Liabilities*/
  $scope.totalInvestmentAssets = 0;
  $scope.totalNonInvestmentAssets = 0;
  $scope.totalLiabilities = 0;
  $scope.totalPropertyLiabilities = 0;
  $scope.totalOutstandingCreditCardDebit = 0;
  $scope.properties = $scope.properties;
  $scope.data3 = $scope.data3;
  $scope.labels3 = $scope.labels3;
  $scope.colors3 = $scope.colors3;
  $scope.data = $scope.data;
  $scope.labels = $scope.labels;
  $scope.colors = $scope.colors;
  $scope.data2 = $scope.data2;
  $scope.labels2 = $scope.labels2
  $scope.colors2 = $scope.colors2;

  $scope.properties = [];
  $scope.data3 = [];
  $scope.labels3 = [];
  $scope.colors3 = [];
  $scope.data = [];
  $scope.labels = [];
  $scope.colors = [];
  $scope.data2 = [];
  $scope.labels2 = [];
  $scope.colors3 = [];
  $scope.loans = [];
  $scope.propertyLVR = [];
  $scope.propertyAVL80 = [];
  $scope.propertyCurrentIO = [];
  $scope.propertyTotalRepayment = [];
  $scope.propertyTotalBalance = [];
  $scope.totalCurrentIO = 0;

  $scope.$on("dashboard-calculation", function (evt, reqData) {

    $scope.clients = reqData.clients;
    $scope.client1FullName = reqData.client1FullName;
    $scope.client2FullName = reqData.client2FullName;
    $scope.legacyId = reqData.legacyId;
    $scope.clientSchema = null
    $scope.clientSchema = reqData.clientSchema;

    // /*Dashboard Tiles Variables*/
    $scope.householdIncome = reqData.householdIncome;
    $scope.taxPayable = reqData.taxPayable;
    $scope.surplusCashflow = reqData.surplusCashflow;
    $scope.totalCash = reqData.totalCash;
    $scope.availableEquity = reqData.availableEquity;
    $scope.investmentNestegg = 0;

    $scope.totalIncomeVal = reqData.totalIncomeVal;
    $scope.totalExpenses = reqData.totalExpenses;
    $scope.totalAssets = reqData.totalAssets;
    $scope.empowerData = empowerDataService;

    // Hold client 1 and 2 ids
    $scope.client1Id = reqData.client1Id;
    $scope.client2Id = reqData.client2Id;

    // Gross annual rental income
    $scope.annualRentalIncome = {
      client1GrossRentalIncome:0,
      client2GrossRentalIncome:0
    }
    /*Property Portfolio*/
    //Need an array of properties.
    $scope.loansPropertyList = reqData.loansPropertyList;
    $scope.nonLoanPropertyList = reqData.nonLoanPropertyList;
    //Need an array of Loans.
    $scope.totalPropertiesValue = reqData.totalPropertiesValue;

    /*Property Loans*/
    $scope.loansTotalLimit = reqData.loansTotalLimit;
    $scope.loansTotalBalance = reqData.loansTotalBalance;
    $scope.loansTotalRepayment = reqData.loansTotalRepayment;
    $scope.loansBalance = reqData.loansBalance;
    $scope.currentLvr = reqData.currentLvr;
    $scope.avlEquityAtEighty = reqData.avlEquityAtEighty;
    $scope.repayments = reqData.repayments;

    /*Summary of Income*/
    /*[0] - Client 1, [1] - Client 2*/
    $scope.grossPaygIncome = reqData.grossPaygIncome;
    $scope.superannuationSalarySacrifice = reqData.superannuationSalarySacrifice;
    $scope.grossBusinessIncome = reqData.grossBusinessIncome;
    $scope.grossSoleTraderIncome = reqData.grossSoleTraderIncome;
    $scope.grossOtherIncome = reqData.grossOtherIncome;
    $scope.grossRentalIncome = reqData.grossRentalIncome;
    $scope.grossInvestmentIncome = reqData.grossInvestmentIncome;
    $scope.preTaxDeductions = reqData.preTaxDeductions;
    $scope.totalIncome = reqData.totalIncome;

    $scope.investmentPropertyDeductions = reqData.investmentPropertyDeductions;
    $scope.otherTaxDeductions = reqData.otherTaxDeductions;
    $scope.taxableIncome = reqData.taxableIncome;
    $scope.estimatedTaxPaid = reqData.estimatedTaxPaid;
    $scope.nonTaxableIncome = reqData.nonTaxableIncome;
    $scope.totalNetIncome = reqData.totalNetIncome;

    /*Summary of Expenditure*/
    /*[0] - Monthly, [1] - Yearly*/
    $scope.totalBillPayments = reqData.totalBillPayments;
    $scope.totalSpending = reqData.totalSpending;
    $scope.totalInvestmentPropertyCosts = reqData.totalInvestmentPropertyCosts;
    $scope.totalOtherPropertyCosts = reqData.totalOtherPropertyCosts;
    $scope.totalLoanPayments = reqData.totalLoanPayments;
    $scope.totalContributionsOtherInvestments = reqData.totalContributionsOtherInvestments;
    $scope.afterTaxSuperContributions = reqData.afterTaxSuperContributions;

    $scope.totalExpenditure = reqData.totalExpenditure;


    /*[0] - Monthly, [1] - Yearly*/
    $scope.incomeOverall = reqData.incomeOverall;
    $scope.expenditureOverall = reqData.expenditureOverall;
    $scope.surplus = reqData.surplus;

    /*Summary of Assets and Liabilities*/
    $scope.totalInvestmentAssets = reqData.totalInvestmentAssets;
    $scope.totalNonInvestmentAssets = reqData.totalNonInvestmentAssets;
    $scope.totalLiabilities = reqData.totalLiabilities;
    $scope.totalPropertyLiabilities = reqData.totalPropertyLiabilities;
    $scope.totalOutstandingCreditCardDebit = reqData.totalOutstandingCreditCardDebit;

    $scope.properties = reqData.properties;
    $scope.data3 = reqData.data3;
    $scope.labels3 = reqData.labels3;
    $scope.colors3 = reqData.colors3;
    $scope.data = reqData.data;
    $scope.labels = reqData.labels;
    $scope.colors = reqData.colors;
    $scope.data2 = reqData.data2;
    $scope.labels2 = reqData.labels2;
    $scope.colors2 = reqData.colors2;

    $scope.loans = reqData.loans;
    $scope.propertyLVR = reqData.propertyLVR;
    $scope.propertyAVL80 = reqData.propertyAVL80;
    $scope.propertyCurrentIO = reqData.propertyCurrentIO;
    $scope.propertyTotalRepayment = reqData.propertyTotalRepayment;
    $scope.propertyTotalBalance = reqData.propertyTotalBalance;
    $scope.totalCurrentIO = reqData.totalCurrentIO;

  });

  $scope.getCrossCollateralLoanColor = function(loanID, forOval){
      
    var loanIndex = 0;

    for (var index = 0; index < $scope.loans.length; index++) {
      var loan =  $scope.loans[index];
      if(loan._id === loanID && loan.securedAgainst.length > 1) {
        loanIndex = index+1;
        break;
      }
    }
    if(loanIndex < 1) {
      return "";
    }  else {
      
      if(forOval && loanIndex > 0) {
        return "dashboard-loan-oval-"+loanIndex;
      }
      return "dashboard-loan-"+loanIndex;
    }
    return "";
  }

  $scope.checkIfIDExistsInSecuredAgaints = function(propertyID) {
    const arr = $scope.loans;
    const found = arr.some(el => el.securedAgainst.includes(propertyID));
    return found;
  }

  $scope.filterPropertyLoans = function(propertyID) {
    
    return function(loan) {
      
        var propertyListIds = [];

      if(loan) {
        
       // if(loan.type){
         // if(loan.type.indexOf('Investment Loan') != -1 || loan.type.indexOf('Home Loan') != -1){
            if(loan.securedAgainst){
              //if(_.isEqual(loan.securedAgainst.sort(), propertyListIds.sort())) {
              if(loan.securedAgainst.includes(propertyID)){  
                return true;
              }
            }
        //  }
       // }
      }
      
      return false;
    };
    
  }

  $scope.state = $state.current;
  $scope.params = $stateParams;

  $scope.closeModal = function () {
    $scope.showDashboardError = false;
  }

  $scope.chartOption = {
    cutoutPercentage:64,
    tooltipEvents: [],

    tooltips: {
      bodyFontSize: 18,
      callbacks: {
        label: function(tooltipItem, data) {
          var value = data.datasets[0].data[tooltipItem.index];
          var datasetLabel = data.datasets[tooltipItem.datasetIndex].label || 'Other';
          var label = data.labels[tooltipItem.index];
          var hello = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return "$"+hello;
        }, title: function(tooltipItem, data) {
          var title = data.labels[tooltipItem[0].index];
          return title;
        }
      }
    }
    ,
    animation:{
      duration:1500,
      onComplete: function() {
       
      }
    },
    maintainAspectRatio: false
  };

})

.controller('dashboard-search-controller', function($scope, FileSaver, DataM, $timeout, Upload, DataHelper, Auth, DataMSPortal,$uibModal) {
  'use strict';
  $scope.clients = [];
  $scope.user = {};
  $scope.showSpinner = true;
  $scope.selectedClient = null;
  $scope.showAlertModal = false;
  $scope.showSuccessModal = false;
  $scope.showUploadModal = false;
  $scope.showSuccessUploadModal = false;
  $scope.flexResponseMessage = '';
  $scope.flexOpportunityID = '';
  $scope.showConfirmFlexSend = false;
  $scope.isChangeNumberVisible = false;
  $scope.isResponsePhoneNumberVisible = false;
  $scope.sms2FA = {};
  $scope.showDownloadJSON = false;
  $scope.showSubmitFlex = false;
  $scope.showMBWarning = false;
  $scope.canSeeUserTypeToggle = false;
  $scope.showFPWorkbench = false;
  $scope.phoneError = null;
  $scope.colWidth = 3;
  $scope.flexLoanWriter = {
    usernameToken:'',
    passwordText:'',
    description:''

  };
  $scope.fileUpload = {

  };
  $scope.maskData = false;
  $scope.showErrorModal = false;
  $scope.responseErrorMessage = "";

  $scope.isSuperAdmin = false;

  $scope.loadClient = function (data){
    if (data && data.clientId){
      location.href = '/dashboard/'+data.clientId;
    }
  }

  $scope.openTransferTPCModal = function () {
    var modalInstance = $uibModal.open({
      animation: $scope.animationsEnabled,
      templateUrl: 'app/dashboard/modals/tpc-switch-modal.html',
      controller: 'SwitchTPCModalController',
      resolve: {
        result: function () {
          return {};
        }
      }
    });

    modalInstance.result.then(function (result) {
      if(result.transfer == true) {
        var isOverwrite = false;
        if(result.transferType == 'FullOverWrite'){
          isOverwrite = true;
        }
        if(result.transferType !== 'TransferMSData'){
          $scope.transferClientData(isOverwrite);
        } else {
          $scope.transferMSData();
        }
        // transfer
        // call api
        // check type of transfer
        
      }
    }, function () {
      // if cancelled
    });
  }
  $scope.openTransferResultModal = function (result) {
    $uibModal.open({
      animation: $scope.animationsEnabled,
      templateUrl: 'app/dashboard/modals/tpc-switch-modal-result.html',
      controller: 'SwitchTPCModalController',
      resolve: {
        result: function () {
          return result;
        }
      }
    });
  }
  $scope.transferMSData = function(){
    $scope.showSpinner = true;

    DataMSPortal.transferMSData({userId:$scope.selectedClient.uid})
      .then(function(data){
        $scope.showSpinner = false;
        var result = {
          success:data.success,
          monthlyBalance:{ message: "Successfully transferred Monthly Balance Data to EW DB."},
          provisioning:{ message: "Successfully transferred Provisioning Data to EW DB."},
          data: data
        };

        if(data.monthlyBalance && data.monthlyBalance.message){
          result.monthlyBalance.message = data.monthlyBalance.message;
        } 
        if(data.provisioning && data.provisioning.message){
          result.provisioning.message = data.provisioning.message;
        }
        $scope.openTransferResultModal(result);
      })
  }
  $scope.transferClientData = function(isOverwrite){
    $scope.showSpinner = true;
    DataMSPortal.transferClientData({userId:$scope.selectedClient.uid,isOverwrite:isOverwrite})
      .then(function(data){
        $scope.showSpinner = false;
        var result = {
          success:data.success,
          message:'Successfully transferred TPC Client Data to EW DB.',
          data: data
        };
        if(data.mesesage) {
          result.message = data.mesesage;
        }
        $scope.openTransferResultModal(result);
        
      })
  }
  DataM.getMe().then(function (userData) {
    const userRole = userData?.role

    if (typeof userRole !== 'string') return;

    switch (userRole) {
      case "super_admin":
        $scope.isSuperAdmin = true;

      case "mb_admin":
        $scope.showMBWarning = true;

      case "pwp_admin":
        $scope.canSeeUserTypeToggle = true;
  
      default:
        // pass
    } 
  }).catch(function (err) {
    console.log('Retrieve current user error');
  });

  // Search Users
  let searchTimeout = null;
  let lastSearch = '';

  $scope.searchClients = function (event, elm){
    $timeout.cancel(searchTimeout);
    searchTimeout = $timeout(function (){
      const searchPrefix = $scope.selected.substr(0, 3);
      if ($scope.selected.length >= 3 && searchPrefix != lastSearch){
        $scope.showSpinner = true;
        if (!searchPrefix.match(/^\d+/)){
          lastSearch = searchPrefix;
        }

        DataM.searchClients($scope.selected).then( function(data) {
          $timeout(function (){
            $scope.clients = data;
            $scope.showSpinner = false;
            $scope.$broadcast('refreshTypeAhead');
          });
        });
      }
    }, 100);
  }

  $scope.showSpinner = false;
  /*
  DataM.getAllClients().then( function(data) {
    
    $scope.clients = data;
    $scope.showSpinner = false;

  }).catch( function(err) {console.log("get clients error");});
  */

  $scope.onSelect = function ($item, $model, $label) {
    $scope.selectedClient = $item;

    DataM.getUserType({id:$scope.selectedClient.uid}).then( function(data) {
 
      if(data.success=== true){
        $scope.selectedClient.userType = data.type;
      }
  
    }).catch( function(err) {console.log("get user type error");});

    DataM.getAuthenticator({id:$scope.selectedClient.uid}).then( function(data) {
      if(data.success=== true){
        $scope.selectedClient.authenticator = data.authenticator;
      }
    }).catch( function(err) {console.log("get user authenticator error");});

    DataM.getIsFuturePlansEnabled({id:$scope.selectedClient.uid}).then( function(data) {
 
      if(data.success=== true){
        $scope.selectedClient.isFuturePlansEnabled = data.isFuturePlansEnabled;
      }
  
    }).catch( function(err) {console.log("get user type error");});
    
  };
  $scope.toggleMaskData = function(){
    $scope.maskData = !$scope.maskData;
  }

  $scope.setMBWarningEnabled = function(){
      $scope.selectedClient.mbWarningEnabled = !$scope.selectedClient.mbWarningEnabled;
      var formData = {uid:$scope.selectedClient.uid, mbWarningEnabled:$scope.selectedClient.mbWarningEnabled};
      DataM.saveMBWarningEnabled(formData).then(function(data){
        
      }).catch(function(err){
        console.log(err);
      });
  }
  $scope.setUserType = function(type){
    $scope.selectedClient.userType= type;

    const formData = {
      uid:$scope.selectedClient.uid,
      type: $scope.selectedClient.userType
    };

    DataM.saveUserType(formData)
      .then((response) => {
        const isFuturePlansEnabled = response?.data?.isFuturePlansEnabled

        if (typeof isFuturePlansEnabled === 'boolean') {
          $scope.selectedClient.isFuturePlansEnabled = isFuturePlansEnabled;
        }
      })
      .catch(function(err){
        console.log(err);
      });

    // set default authenticator for user type
    if(type === "tpc") {
      $scope.setAuthenticator('google')
    } else if(type === "ew") {
      $scope.setAuthenticator('sms')
    }
  }
  $scope.setAuthenticator = function(type){
      $scope.selectedClient.authenticator= type;
      var formData = {uid:$scope.selectedClient.uid, authenticator: $scope.selectedClient.authenticator };
      DataM.saveAuthenticator(formData).then(function(data){
        
      }).catch(function(err){
        console.log(err);
      });
  }
  $scope.setIsFuturePlansEnabled = function(){
    $scope.selectedClient.isFuturePlansEnabled = !$scope.selectedClient.isFuturePlansEnabled;
    var formData = {id:$scope.selectedClient.uid, isFuturePlansEnabled:$scope.selectedClient.isFuturePlansEnabled};
    DataM.setIsFuturePlansEnabled(formData).then(function(data){
      
    }).catch(function(err){
      console.log(err);
    });

  // submit to api
}
  DataM.checkPermission({name:"downloadClientJSON"}).then(function(data){
    
    $scope.showDownloadJSON = data.hasPermission;
    if(data.hasPermission !== true) {
      $scope.colWidth = $scope.colWidth == 3 ? 4 : 6;
    }
  }).catch(function(err){
    console.log(err);
    $scope.colWidth = $scope.colWidth == 3 ? 4 : 6;
  });
  
  DataM.checkPermission({name:"submitFlex"}).then(function(data){
    
    $scope.showSubmitFlex = data.hasPermission;
    if(data.hasPermission !== true) {
      $scope.colWidth = $scope.colWidth == 3 ? 4 : 6;
    }
  }).catch(function(err){
    console.log(err);
    $scope.colWidth = $scope.colWidth == 3 ? 4 : 6;
  });

  DataM.checkPermission({name:"showFPWorkbench"}).then(function(data){
    
    $scope.showFPWorkbench = data.hasPermission;
    if(data.hasPermission !== true) {
      $scope.colWidth = $scope.colWidth == 3 ? 4 : 6;
    }
  }).catch(function(err){
    console.log(err);
    $scope.colWidth = $scope.colWidth == 3 ? 4 : 6;
  });

  
  $scope.cleanJSON = function(cl){
      var cloneClient = JSON.parse(JSON.stringify(cl));

      if(cloneClient.futurePlans){
        if(cloneClient.futurePlans.existingFamilyDep){
          if(cloneClient.futurePlans.existingFamilyDep.compositionChange){
            for(var i=0; i<cloneClient.futurePlans.existingFamilyDep.compositionChange.length; i++){
              if(cloneClient.futurePlans.existingFamilyDep.compositionChange[i].expDate){
                var xDate = (cloneClient.futurePlans.existingFamilyDep.compositionChange[i].expDate).substring(0, 4) +
                      "-" +
                      (cloneClient.futurePlans.existingFamilyDep.compositionChange[i].expDate).substring(5, 7) +
                      "-" +
                      "00" +
                      "T00:00:00.000Z"
                cloneClient.futurePlans.existingFamilyDep.compositionChange[i].expDate = xDate;
              }
            }
          }
        }
      }

      //futurePlans.familyPlans.moreKids[].expBirth
      if(cloneClient.futurePlans){
        if(cloneClient.futurePlans.familyPlans){
          if(cloneClient.futurePlans.familyPlans.moreKids){
            for(var i=0; i<cloneClient.futurePlans.familyPlans.moreKids.length; i++){
              if(cloneClient.futurePlans.familyPlans.moreKids[i].expBirth){
                var xDate = (cloneClient.futurePlans.familyPlans.moreKids[i].expBirth).substring(0, 4) +
                      "-" +
                      (cloneClient.futurePlans.familyPlans.moreKids[i].expBirth).substring(5, 7) +
                      "-" +
                      "00" +
                      "T00:00:00.000Z"
                cloneClient.futurePlans.familyPlans.moreKids[i].expBirth = xDate;
              }
            }
          }
        }
      }

      //futurePlans.familyPlans.moreKids[].maternityLeave.expReturnFullTime
      if(cloneClient.futurePlans){
        if(cloneClient.futurePlans.familyPlans){
          if(cloneClient.futurePlans.familyPlans.moreKids){
            if(cloneClient.futurePlans.familyPlans.moreKids.maternityLeave){
              for(var i=0; i<cloneClient.futurePlans.familyPlans.moreKids.maternityLeave.length; i++){
                if(cloneClient.futurePlans.familyPlans.moreKids.maternityLeave[i].expReturnFullTime){
                  var xDate = (cloneClient.futurePlans.familyPlans.moreKids.maternityLeave[i].expReturnFullTime).substring(0, 4) +
                        "-" +
                        (cloneClient.futurePlans.familyPlans.moreKids.maternityLeave[i].expReturnFullTime).substring(5, 7) +
                        "-" +
                        "00" +
                        "T00:00:00.000Z"
                  cloneClient.futurePlans.familyPlans.moreKids.maternityLeave[i].expReturnFullTime = xDate;
                }
              }
            }
          }
        }
      }

      //futurePlans.financialIndependence.client1FinInd.expRetireDate
      if(cloneClient.futurePlans){
        if(cloneClient.futurePlans.financialIndependence){
          if(cloneClient.futurePlans.financialIndependence.client1FinInd){
            if(cloneClient.futurePlans.financialIndependence.client1FinInd.expRetireDate){
              var xDate = (cloneClient.futurePlans.financialIndependence.client1FinInd.expRetireDate).substring(0, 4) +
                    "-" +
                    (cloneClient.futurePlans.financialIndependence.client1FinInd.expRetireDate).substring(5, 7) +
                    "-" +
                    "00" +
                    "T00:00:00.000Z"
              cloneClient.futurePlans.financialIndependence.client1FinInd.expRetireDate = xDate;
            }
          }
        }
      }

      //futurePlans.financialIndependence.client2FinInd.expRetireDate
      if(cloneClient.futurePlans){
        if(cloneClient.futurePlans.financialIndependence){
          if(cloneClient.futurePlans.financialIndependence.client2FinInd){
            if(cloneClient.futurePlans.financialIndependence.client2FinInd.expRetireDate){
              var xDate = (cloneClient.futurePlans.financialIndependence.client2FinInd.expRetireDate).substring(0, 4) +
                    "-" +
                    (cloneClient.futurePlans.financialIndependence.client2FinInd.expRetireDate).substring(5, 7) +
                    "-" +
                    "00" +
                    "T00:00:00.000Z"
              cloneClient.futurePlans.financialIndependence.client2FinInd.expRetireDate = xDate;
            }
          }
        }
      }

      //futurePlans.homeImprovement[].expDate
      if(cloneClient.futurePlans){
        if(cloneClient.futurePlans.homeImprovement){
          for(var i=0; i<cloneClient.futurePlans.homeImprovement.length; i++){
            if(cloneClient.futurePlans.homeImprovement[i].expDate){
              var xDate = (cloneClient.futurePlans.homeImprovement[i].expDate).substring(0, 4) +
                    "-" +
                    (cloneClient.futurePlans.homeImprovement[i].expDate).substring(5, 7) +
                    "-" +
                    "00" +
                    "T00:00:00.000Z"
              cloneClient.futurePlans.homeImprovement[i].expDate = xDate;
            }
          }
        }
      }

      //futurePlans.incomeChanges[].expDate
      if(cloneClient.futurePlans){
        if(cloneClient.futurePlans.incomeChanges){
          for(var i=0; i<cloneClient.futurePlans.incomeChanges.length; i++){
            if(cloneClient.futurePlans.incomeChanges[i].expDate){
              var xDate = (cloneClient.futurePlans.incomeChanges[i].expDate).substring(0, 4) +
                    "-" +
                    (cloneClient.futurePlans.incomeChanges[i].expDate).substring(5, 7) +
                    "-" +
                    "00" +
                    "T00:00:00.000Z"
              cloneClient.futurePlans.incomeChanges[i].expDate = xDate;
            }
          }
        }
      }

      //futurePlans.movingHome.expDate
      if(cloneClient.futurePlans){
        if(cloneClient.futurePlans.movingHome){
          for(var i=0; i<cloneClient.futurePlans.movingHome.length; i++){
            if(cloneClient.futurePlans.movingHome[i].expDate){
              var xDate = (cloneClient.futurePlans.movingHome[i].expDate).substring(0, 4) +
                    "-" +
                    (cloneClient.futurePlans.movingHome[i].expDate).substring(5, 7) +
                    "-" +
                    "00" +
                    "T00:00:00.000Z"
              cloneClient.futurePlans.movingHome[i].expDate = xDate;
            }
          }
        }
      }

      //futurePlans.plannedExpense.expDate
      if(cloneClient.futurePlans){
        if(cloneClient.futurePlans.plannedExpense){
          for(var i=0; i<cloneClient.futurePlans.plannedExpense.length; i++){
            if(cloneClient.futurePlans.plannedExpense[i].expDate){
              var xDate = (cloneClient.futurePlans.plannedExpense[i].expDate).substring(0, 4) +
                    "-" +
                    (cloneClient.futurePlans.plannedExpense[i].expDate).substring(5, 7) +
                    "-" +
                    "00" +
                    "T00:00:00.000Z";
              cloneClient.futurePlans.plannedExpense[i].expDate = xDate;
            }
          }
        }
      }


      //income.client1PersonalIncome.creditHistory[].resolutionDate
      if(cloneClient.income){
        if(cloneClient.income.client1PersonalIncome){
          if(cloneClient.income.client1PersonalIncome.creditHistory){
            for(var i=0; i<cloneClient.income.client1PersonalIncome.creditHistory.length; i++){
              if(cloneClient.income.client1PersonalIncome.creditHistory[i].resolutionDate){
                var xDate = (cloneClient.income.client1PersonalIncome.creditHistory[i].resolutionDate).substring(0, 4) +
                      "-" +
                      (cloneClient.income.client1PersonalIncome.creditHistory[i].resolutionDate).substring(5, 7) +
                      "-" +
                      "00" +
                      "T00:00:00.000Z"
                cloneClient.income.client1PersonalIncome.creditHistory[i].resolutionDate = xDate;
              }
            }
          }
        }
      }

      //income.client2PersonalIncome.creditHistory[].resolutionDate
      if(cloneClient.income){
        if(cloneClient.income.client2PersonalIncome){
          if(cloneClient.income.client2PersonalIncome.creditHistory){
            for(var i=0; i<cloneClient.income.client2PersonalIncome.creditHistory.length; i++){
              if(cloneClient.income.client2PersonalIncome.creditHistory[i].resolutionDate){
                var xDate = (cloneClient.income.client2PersonalIncome.creditHistory[i].resolutionDate).substring(0, 4) +
                      "-" +
                      (cloneClient.income.client2PersonalIncome.creditHistory[i].resolutionDate).substring(5, 7) +
                      "-" +
                      "00" +
                      "T00:00:00.000Z"
                cloneClient.income.client2PersonalIncome.creditHistory[i].resolutionDate = xDate;
              }
            }
          }
        }
      }

      return cloneClient;

  }

  $scope.download = function(fname, lname, clientId) {
    var $clientId = clientId;
    var rightNow = new Date();
    var dFile = rightNow.toISOString().slice(0,10).replace(/-/g,"");
    
    if ($clientId !== null) {
      $scope.showSpinner = true;
      DataM.getClientDoc({ id: clientId })
        .then(function(data) {
          $scope.toJSON = '';
          $scope.showSpinner = false;
          addDefaultsToClientData(data.clientSummary);

          if($scope.maskData == true){
            DataHelper.maskClientData(data.clientSummary)
          }
          var $filename = 'moorr Download ' + dFile + ' - ' + data.clientSummary.personalInfo.client1.fName + " " + data.clientSummary.personalInfo.client1.lName + '.json';
          $scope.toJSON =  angular.toJson($scope.cleanJSON(data.clientSummary));
         // $scope.toJSON =  data.clientSummary;
          var data = new Blob([$scope.toJSON], {type: "application/json;charset=utf-8;"});
          FileSaver.saveAs(data, $filename);
        }).catch(function(err) {console.log('download json error',err); 
        $scope.showSpinner = false;
        $scope.showErrorModal = true;
        $scope.responseErrorMessage = "Current User is not permitted to download client json";
      });
      }
    }

    function addDefaultsToClientData(clientSummary){

      // Properties
      if(clientSummary.assets && clientSummary.assets.properties) {
        var client1ID = clientSummary.personalInfo.client1._id;
        for(var i = 0; i< clientSummary.assets.properties.length; i++) {
          var property = clientSummary.assets.properties[i];

          if(property.ownership && !property.ownership.ownershipType) {
            property.ownership.ownershipType = "Sole";
           
            if(!property.ownership.owners || property.ownership.owners.length == 0) {
              property.ownership.owners = [{owner:client1ID, percentage:100}];
            }
          }
        }
      }

      // Expenses
      if(clientSummary.expenses && clientSummary.expenses.expenses) {
        var expenses = clientSummary.expenses.expenses;
        
        for(var i =0; i< expenses.length; i++) {
          
           var expense = expenses[i];
          if(!expense.hasOwnProperty('frequency')){
            expense.frequency = null;
          }
          if(!expense.hasOwnProperty('amount')){
            expense.amount = null;
          }
        }
      }
    }
    $scope.pushFlex = function(clientId) {
      var $clientId = clientId;
      $scope.xmlPayload = "";
      $scope.payloadDisplayed = false;

      if ($clientId !== null) {
        $scope.showSpinner = true;
        DataM.pushFlex({ userId:  clientId,
          UsernameToken:$scope.flexLoanWriter.usernameToken,
          PasswordText:$scope.flexLoanWriter.passwordText,
          Description:$scope.flexLoanWriter.description })
          .then(function(data) {
            $scope.toJSON = '';
            $scope.showSpinner = false;

            if(data.error){

              $scope.showAlertModal = true;
              $scope.flexResponseMessage = getFlexResponseMessage(data.error);

            }
            else if(!data.error&&data.outboundMsg.Opportunity) {
              if(data.outboundMsg.Opportunity.FLEXId ) {
                $scope.showSuccessModal = true;

                $scope.flexOpportunityID = data.outboundMsg.Opportunity.FLEXId;
                $scope.xmlPayload = data.inboundMsg;
                // reset the input form
                $scope.flexLoanWriter = {
                  usernameToken:'',
                  passwordText:'',
                  description:''
              
                };
              }
            } else {
              $scope.xmlPayload = data.inboundMsg;
              $scope.showAlertModal = true;
              $scope.flexResponseMessage = getFlexResponseMessage(data.outboundMsg);
            }
          }).catch(function(err) {
            console.log('FLEX Error',err);

            $scope.showSpinner = false;
            $scope.showAlertModal = true;
            if(err.status !== 403) {
              $scope.flexResponseMessage = 'Flex Data Mapping error occured';
            } else {
              $scope.flexResponseMessage = 'User not allowed to push in Flex';
            }
          });
        }
      }

      $scope.closeModal = function () {
        $scope.showAlertModal = false;  
        $scope.showSuccessModal = false;    
        $scope.showConfirmFlexSend = false;    
        $scope.showUploadModal = false;
        $scope.showSuccessUploadModal = false;
        $scope.showErrorModal = false;
        $scope.isChangeNumberVisible = false;
        $scope.isResponsePhoneNumberVisible = false;
        $scope.isConfirmReset2FAVisible = false;
        $scope.isResetResponseModalVisible = false;
        $scope.isIressXplanVisible = false;
      }


      $scope.submitToFlex = function () {
        $scope.showAlertModal = false;  
        $scope.showSuccessModal = false;
        $scope.showConfirmFlexSend = false;

        // push to flex
        
        $scope.pushFlex($scope.selectedClient.uid);

      }
      $scope.showFlexInputModal = function (){
        $scope.showConfirmFlexSend = true;
      }
      $scope.showUploadDocumentModal = function(){
        $scope.showUploadModal = true;
      }

      $scope.showChangeNumberForm = function(){
          $scope.isChangeNumberVisible = true;
      }

      $scope.update2FAPhoneNumber = function(form){
       
        // Load
        $scope.showSpinner = true;
        $scope.isChangeNumberVisible = false;

        if (form.$valid && $scope.sms2FA.phoneNumber && $scope.sms2FA.phoneNumber != "") {
          var mobileNumber = (form.tel.$viewValue).replace(/\s+/g, "");
          var countryCode = $scope.sms2FA.phoneNumber.replace(
            mobileNumber,
            ""
          ).substring(1);
          var uid = $scope.selectedClient.uid;

          // Call request
          Auth.change({
            countryCode: countryCode,
            mobileNumber: mobileNumber,
            uid: uid
          })
          .then(res =>  {
            $scope.showSpinner = false;
            $scope.isResponsePhoneNumberVisible = true;
            $scope.phoneError = null;
            $scope.sms2FA = {};
          })
          .catch( function(err) {
            console.log('Phone number update error:',err);
            $scope.phoneError = 'Error updating to two factor authentication. Please verify the phone number you have entered.';
            $scope.sms2FA = {};
            $scope.isResponsePhoneNumberVisible = true;
            $scope.showSpinner = false;
          });
        } else {
          $scope.errors.msg = "Please enter a valid phone number.";
        }
      }

      function getFlexResponseMessage(flexResponse){
        var responseMessage = "";
        try {
        if(flexResponse.missingFields){
          responseMessage = "Fields Required: \n"
          responseMessage += flexResponse.missingFields.join("\n");
        }
        else if(flexResponse.headers) {
          if(flexResponse.headers['siebel-error-message-1']){
            responseMessage = flexResponse.headers['siebel-error-message-1'];
          }  else {
            if(flexResponse.body){
              if(flexResponse.body['SOAP-ENV:Envelope'] && flexResponse.body['SOAP-ENV:Envelope']['SOAP-ENV:Body'] && flexResponse.body['SOAP-ENV:Envelope']['SOAP-ENV:Body'][0]) {
                
                if(flexResponse.body['SOAP-ENV:Envelope']['SOAP-ENV:Body'][0]['SOAP-ENV:Fault'] && flexResponse.body['SOAP-ENV:Envelope']['SOAP-ENV:Body'][0]['SOAP-ENV:Fault'][0]['faultstring']){
                  responseMessage = flexResponse.body['SOAP-ENV:Envelope']['SOAP-ENV:Body'][0]['SOAP-ENV:Fault'][0]['faultstring'];
                }
                else {
                  responseMessage = "Server error occured during flex push. Please try again later."
                }
              } else {
                responseMessage = "Server error occured during flex push. Please try again later."
              }
            }
          }
        } 
      
       else {
          responseMessage = "Server error occured during flex push. Please try again later."
        }
        return responseMessage;
      }
      catch(err) {
        console.log('Flex response error');
        var responseMessage = "Server error occured during flex push. Please try again later."
        return responseMessage;
      } 
    }
    
    
   //  on file select or drop
   $scope.submitUpload = function (file) {
    
    $scope.showSpinner = true;
    $scope.showUploadModal = false;

     Upload.upload({
         url: '/api/aws/upload',
         data: {
          clientDoc: $scope.fileUpload.file, 
          'clientDocType':$scope.fileUpload.type,
          clientId:$scope.selectedClient.uid,
          clientEmail:$scope.selectedClient.personalInfo.client1.email
        }

     }).then(function (resp) {
        $scope.showSpinner = false;

        // show success modal
        $scope.showSuccessUploadModal = true;

     }, function (resp) {
        $scope.showSpinner = false;
        console.log('Error status: ' + resp.status);

     }, function (evt) {
         var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
         console.log('evt: ' + progressPercentage + '% ' , evt);
     }).catch(function(err){

     });
   };

    // Reset 2FA
    $scope.showReset2FA = function(){
      $scope.isConfirmReset2FAVisible = true;
    };

    $scope.reset2FA = function() {
      var payload = {uid: $scope.selectedClient.uid};
      $scope.showSpinner = true;
      DataM.resetUser2FA(payload).then(function (data) {
        $scope.reset2FAError = null;
        $scope.showSpinner = false;
        $scope.isResetResponseModalVisible = true;
      }).catch(function(err){
        $scope.showSpinner = false;
        $scope.isResetResponseModalVisible = true;
        $scope.reset2FAError = "Reset authenticator unsuccessful. Server error occured.";
      });
    }

    
    // Financial Planning
    function RenderXplan(params) {
      params.uid = $scope.selectedClient.uid;

      let reactWrapper = document.getElementById("react-xplan-wrapper");
      ReactDOM.unmountComponentAtNode(reactWrapper);
      ReactDOM.render(
        React.createElement(FPWorkbench.default, params),
        reactWrapper
      );

      $scope.$on("$destroy", () => {
        ReactDOM.unmountComponentAtNode(reactWrapper);
      });
      
    }

    $scope.showIressXplan = function(){
      
      let params = {
        uid: {},
        showModal: true,
        onClose: () => {
          RenderXplan({uid:{}, showModal: false})
        }
      }

      RenderXplan(params);
    };



    // User Admin
    function RenderUManagement(params) {

      params.uid = $scope.selectedClient.uid;

      let wrapperComponent = document.getElementById("user-management-wrapper");
      ReactDOM.unmountComponentAtNode(wrapperComponent);
      ReactDOM.render(
        React.createElement(UserManagement.default, params),
        wrapperComponent
      );

      $scope.$on("$destroy", () => {
        ReactDOM.unmountComponentAtNode(wrapperComponent);
      });
    }

    $scope.showUserManagement = function(){
      
      let params = {
        uid: {},
        showModal: true,
        onClose: () => {
          RenderUManagement({uid:{}, showModal: false})
        },
        onCloseRedirect: () => {
          RenderUManagement({uid:{}, showModal: false})
          window.location.replace("dashboard-search")
        }
      }

      RenderUManagement(params);
    };

  })

  .filter('searchFor', function(){
  return function(arr, searchString){
    if(!searchString){
      return arr;
    }
    var result = [];
    searchString = searchString.toLowerCase();
    angular.forEach(arr, function(item){
      var name = item.email.toLowerCase();
      if(name.toLowerCase().indexOf(searchString) !== -1) {
        result.push(item);
      }
    });
    return result;
  };

 
}).directive('linearChart', function($window){
  return{
     restrict:'EA',
     template:"<svg width='850' height='200'></svg>",
      link: function(scope, elem, attrs){
          var salesDataToPlot=scope[attrs.chartData];
          var padding = 20;
          var pathClass="path";
          var xScale, yScale, xAxisGen, yAxisGen, lineFun;

          var d3 = $window.d3;

          var rawSvg=elem.find('svg');
          var svg = d3.select(rawSvg[0]);
      
          function setChartParameters(){
            xScale = d3.scaleLinear()
                       .domain([salesDataToPlot[0].hour, salesDataToPlot[salesDataToPlot.length - 1].hour])
                       .range([padding + 5, rawSvg.attr("width") - padding]);
          
              yScale = d3.scaleLinear()
                          .domain([0, d3.max(salesDataToPlot, function (d) {
                            return d.sales;
                          })])
                       .range([rawSvg.attr("height") - padding, 0]);
          
            xAxisGen = d3.axisBottom()
                         .scale(xScale)
                         .ticks(salesDataToPlot.length - 1);
          
            yAxisGen = d3.axisLeft()
                         .scale(yScale)
                         .ticks(5);
          
            lineFun = d3.line()
                        .x(function (d) {
                          return xScale(d.hour);
                        })
                        .y(function (d) {
                          return yScale(d.sales);
                        })
                        .curve(d3.curveBasis)
          }
                   
          function drawLineChart() {
          
            setChartParameters();
          
            svg.append("svg:g")
               .attr("class", "x axis")
               .attr("transform", "translate(0,180)")
               .call(xAxisGen);
          
             svg.append("svg:g")
                .attr("class", "y axis")
                .attr("transform", "translate(20,0)")
                .call(yAxisGen);
             svg.append("svg:path")
             .attr("d",lineFun(salesDataToPlot))
             .attr("stroke","blue")
             .attr("fill","none")
             .attr("stroke-width",2)
             .attr("class",pathClass);
          }
          
          drawLineChart();
      }
  };
})
angular.module('meanApp').controller('SwitchTPCModalController', function ($scope, $uibModalInstance,result) {

  $scope.result = result;
  $scope.transferType = null;
  $scope.transfer = function () {
    $uibModalInstance.close({transfer:true, transferType:$scope.transferType});
  };

  $scope.close = function () {
    $uibModalInstance.dismiss('cancel');
  };
});